<template>
  <b-card
    v-if="blogData!=null"
    class="blog-edit-wrapper"
  >
    <!-- media -->
    <b-media
      no-body
      vertical-align="center"
    >
      <b-media-aside class="mr-75">
        <b-avatar
          size="38"
          :src="blogData.authorImage"
        />
      </b-media-aside>
      <b-media-body>
        <h6 class="mb-25">
          {{ blogData.author }}
        </h6>
        <b-card-text>{{ blogData.createdAt | formattedDate }}</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col md="12">
          <b-form-group
            label="Blog Title"
            label-for="blog-edit-title"
            class="mb-2"
          >
            <b-form-input
              id="blog-edit-title"
              v-model="blogData.title"
            />
           
          </b-form-group>
        </b-col>
        
        <b-col md="6">
          <b-form-group
            label="Page Key"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <b-form-input
              id="blog-edit-slug"
              v-model="blogData.page_key"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Status"
            label-for="blog-edit-category"
            class="mb-2"
          >
            <v-select
              id="blog-edit-category"
              v-model="blogStatus"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOption"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Content"
            label-for="blog-content"
            class="mb-2"
          >

           <vue-editor   id="editor"
       
       :editorOptions="editorSettings"
      @imageAdded="handleImageAdded"
       v-model="blogData.content" />

           
             
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          class="mb-2"
        >
          <div class="border rounded p-2">
            <h4 class="mb-1">
              Featured Image
            </h4>
            <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img
            ref="previewEl"
            rounded
            :src="blogData.featuredImage"
            height="240"
            alt=""
            width="480"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          :state="Boolean(file)"
          v-model="file"
          @click="$refs.refInputEl.$el.click()"
        >
          Upload
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="profileFile"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @input="fileSelected"
        />
        <!--/ upload button -->

        <!-- reset -->

        <!--/ reset -->
        <b-card-text>Allowed JPG, GIF or PNG. Max size of 2 MB</b-card-text>
      </b-media-body>
    </b-media>
          </div>
        </b-col>
        <b-col
          cols="10"
          class="mt-50"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            @click.prevent="sendInformation"
          >
            Save Changes
          </b-button>
         
        </b-col>

        <b-col
          cols="2"
          class="mt-50"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            class="mr-1"
            @click.prevent="deleteContent"
          >
            Delete Page
          </b-button>
         
        </b-col>

      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,  BMedia, BAvatar, BCardText, BMediaAside, BMediaBody, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from 'moment'
import { VueEditor , Quill } from "vue2-editor"; 
import router from '@/router'
import store from '@/store'
 import courseStoreModule from '../departmentStoreModule'
export default {
  components: {
    BCard, 
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect, 
    quillEditor,
    VueEditor
  },
  directives: {
    Ripple,
  },
  filters: {
        formattedDate: function (value) {
          if (value) {
        return moment(String(value)).format('DD MMM YYYY  HH:mm')
    }
        }
    },
  computed:{
    blogStatus:
    {
      get()
      {
          var result= this.blogData.status==0 ? 'Draft' : 'Published' ;
        return result;
      },
      set(newValue)
      {
          if (newValue=='Draft')
          {
            this.blogData.status=0;
          } else
          {
            this.blogData.status=100;
          }
      }
    }
  },
  methods: {
    moment: function () {
    return moment();
  },
     setEditorContent() {
      this.content = this.blogData.excerpt;
    }
    ,  resetFile() {
      this.file = null;
      this.profileFile = null;
    },
    clearFiles() {
      this.$refs["file-input"].reset();
    },

    blogCategoryUpdated(payload) {
      //console.log("Blog categories updated");
      //console.log(payload);
      this.blogData.BlogCategories = Array.isArray(payload)
        ? payload
        : [payload];
    },
    fileSelected(payload) {
      const formData = new FormData();
      formData.append("file", payload);
      formData.append("postid", 2);
      formData.append("acc", 3);
      formData.append("platform", "webadmin");
      this.isLoading = true;

      axios
        .post("/upload/admin", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response);
          this.blogData.featuredImage = response.data.url;
          this.isUpdated = true;
          this.isLoading = false;
          this.$bvToast.toast(`${response.data.message}`, {
            title: "Success",
            autoHideDelay: 5000,
            appendToast: true,
            variant: "success",
            solid: true,
          });
        })
        .catch((error) => {
          //console.log(error);
          this.isLoading = false;
          this.$bvToast.toast(`${error.response.data.message}`, {
            title: "Error",
            autoHideDelay: 5000,
            appendToast: true,
            variant: "danger",
            solid: true,
          });
        });
    }, sendInformation() {
    //  //console.log(this.optionsLocal);
    //  //console.log("update course  is launching with id : " + this.courseId);

     //console.log("sending blog data");
     //console.log(this.blogData);


      this.isLoading = true;
      axios({
        method: "put",
        url: `/cms/admin/updateblogpost`,
        data: {
          info: "general",
          file: null,
          content: "",
          post:this.blogData
        },
        
      })
        .then((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success`,
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });
          this.isLoading = false;
          this.isUpdated = false;
        })
        .catch((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertOctagonIcon",
              variant: "warning",
            },
          });
          this.isLoading = false;
        });
      return;
    },
    deleteContent() {
    //  //console.log(this.optionsLocal);
    //  //console.log("update course  is launching with id : " + this.courseId);

     //console.log("sending blog data");
     //console.log(this.blogData);
     this.blogData.deleted=true,

      this.isLoading = true;
      axios({
        method: "put",
        url: `/cms/admin/updateblogpost`,
        data: {
          info: "general",
          file: null,
          content: "",
          post:this.blogData
        },
        
      })
        .then((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success`,
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });
          this.isLoading = false;
          this.isUpdated = false;
          router.push({ name: 'cms-pages',  } )

        })
        .catch((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertOctagonIcon",
              variant: "warning",
              text:r
            },
          });
          this.isLoading = false;
        });
      return;
    },
    handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)

      var formData = new FormData();
      formData.append("image", file);
        Editor.insertEmbed(cursorLocation, "image", 'https://cdn.niso.dev/8c1fb068-5aaf-4f37-9a88-a4debe1509ca/734a3c12-728c-41b2-96b6-c181e129effb.png');
          resetUploader();
      // axios({
      //   url: "https://fakeapi.yoursite.com/images",
      //   method: "POST",
      //   data: formData
      // })
      //   .then(result => {
      //     const url = result.data.url; // Get url from response
      //     Editor.insertEmbed(cursorLocation, "image", url);
      //     resetUploader();
      //   })
      //   .catch(err => {
      //     //console.log(err);
      //   });
    }
  },
  data() {
  
       var toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  ['blockquote', 'code-block'],

  [{ 'header': 1 }, { 'header': 2 }],               // custom button values
  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
  [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
          // outdent/indent
  [{ 'direction': 'rtl' }],                         // text direction

  [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
  [{ 'font': [] }],
  [{ 'align': [] }],

  ['clean'],                                         // remove formatting button
  ['link', 'image', 'video'],
 	['html']
];

    return {
        editorSettings: {
        modules: {
           
        }
      }, profileFile: null,
      isLoading: false,
      file: null,
      blogEdit: {
    avatar: require('@/assets/images/portrait/small/avatar-s-9.jpg'),
    userFullName: 'Ozgur SALGINCI',
    createdTime: 'May 24, 2022',
    blogTitle: 'Energy Management Procedure',
    blogCategories: ['HR Commmunication', 'Memorandum'],
    slug: 'energy-management-procedure',
    status: 'Published',
    excerpt:
      '<p>Cupcake ipsum dolor sit. Amet dessert donut candy chocolate bar cotton dessert candy chocolate. Candy muffin danish. Macaroon brownie jelly beans marzipan cheesecake oat cake. Carrot cake macaroon chocolate cake. Jelly brownie jelly. Marzipan pie sweet roll.</p><p><br></p><p>Liquorice dragée cake chupa chups pie cotton candy jujubes bear claw sesame snaps. Fruitcake chupa chups chocolate bonbon lemon drops croissant caramels lemon drops. Candy jelly cake marshmallow jelly beans dragée macaroon. Gummies sugar plum fruitcake. Candy canes candy cupcake caramels cotton candy jujubes fruitcake.</p>',
    featuredImage: require('@/assets/images/slider/03.jpg'),
  },
      blogFile: null,
      categoryOption: ['HR Commmunication', 'Memorandum', 'Announcement', 'Info', 'Weekly News'],
      statusOption: [ 'Draft','Published'],
      snowOption: {
          modules: {
    toolbar: toolbarOptions
  },
        theme: 'snow',
      },
    }
  },
  created() {
   // this.$http.get('/blog/list/data/edit').then(res => { this.blogEdit = res.data })
  },
  mounted(){
   
    
  },
  setup() {
    const refInputEl = ref(null)
    const refPreviewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => { refPreviewEl.value.src = base64 })


    const blogData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'app-blog-edit'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, courseStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-blog-edit/fetchPost', { id: router.currentRoute.query.p })
      .then(response => {
   //    var course=JSON.parse(JSON.stringify(response.data));
      //  const { course  } = response.data
      blogData.value =  response.data.data;
      //console.log(response.data);
        //console.log("  assigned to blog data");
        // //console.log(response.data);
        
        
        })
      .catch(error => {
       //console.log(error);
        // if (error.response.status === 404) {
        //   blogData.value = undefined
        // }
      })

    

    return {
      blogData,
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';

.ql-showHtml:after {
  content: "[source]";
}

</style>
